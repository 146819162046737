const UPCOMING = 0;
const SEATED = 1;
const CANCELLED = 2;
const FORCED = 3;
const NEW = 4;
const REQUESTED = 5;
const DONE = 6;
const REFUSED = 7;
const NO_SHOW = 8;
const BLOCK = 9;
const PREPARED = 10;
const PRESEATED = 11;
const WAITLIST = 12;

export const preDoneStates = [UPCOMING, SEATED, FORCED, NEW, REQUESTED, PREPARED, WAITLIST, CANCELLED];

export const states = {
  UPCOMING,
  SEATED,
  CANCELLED,
  FORCED,
  NEW,
  REQUESTED,
  DONE,
  REFUSED,
  NO_SHOW,
  BLOCK,
  PREPARED,
  PRESEATED,
  WAITLIST,
  [UPCOMING]: 'UPCOMING',
  [SEATED]: 'SEATED',
  [CANCELLED]: 'CANCELLED',
  [FORCED]: 'FORCED',
  [NEW]: 'NEW',
  [REQUESTED]: 'REQUESTED',
  [DONE]: 'DONE',
  [REFUSED]: 'REFUSED',
  [NO_SHOW]: 'NO_SHOW',
  [BLOCK]: 'BLOCK',
  [PREPARED]: 'PREPARED',
  [PRESEATED]: 'PRESEATED',
  [WAITLIST]: 'WAITLIST',
};

export const changeableStates = [UPCOMING, NEW, PREPARED]